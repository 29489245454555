var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import ModuleHeader, { PORTAL_STATUS } from '../../components/ModuleHeader/ModuleHeader';
import ModuleTable from '../../components/UtilityComponents/moduleTable';
import { UserMangementCols } from '../../config/TableColumns';
import { api_service } from '../../api/api_service';
import { api_urls } from '../../api/api_urls';
import moment from 'moment';
import ConfirmationAndInformationPoup, { POPUP_TYPE } from '../../components/UtilityComponents/ConfirmationAndInformationPoup';
import UserModuleDrawer from './UserModuleDrawer';
var freezedUserList = [];
var UserManagement = function () {
    var _a = useState(null), selectedPortal = _a[0], setSelectedPortal = _a[1];
    var _b = useState(), selectedStatus = _b[0], setSelectedStatus = _b[1];
    var _c = useState(), searchText = _c[0], setSearchText = _c[1];
    var _d = useState(undefined), vendorList = _d[0], setVendorList = _d[1];
    var _e = useState([]), dealerList = _e[0], setDealerListToDisplay = _e[1];
    var _f = useState(false), isFetching = _f[0], setIsFetching = _f[1];
    var _g = useState(false), openSuccess = _g[0], setOpenSuccess = _g[1];
    var _h = useState(''), msgText = _h[0], setMsgText = _h[1];
    var _j = useState(), rowData = _j[0], setRowData = _j[1];
    var _k = useState(false), open = _k[0], setOpen = _k[1];
    var getDealerListBasedOnVendor = function () {
        setIsFetching(true);
        setDealerListToDisplay([]);
        freezedUserList = [];
        api_service.get({
            endPoint: api_urls.DEALER_LIST,
            payLoad: { PortalId: selectedPortal }
        }).then(function (res) {
            setIsFetching(false);
            setSelectedStatus(PORTAL_STATUS[0].code);
            if ((res === null || res === void 0 ? void 0 : res.data) && res.data.statusCode === 200) {
                var dealerList_1 = res.data.data;
                dealerList_1.map(function (eachDealer, index) {
                    eachDealer.CreatedTime = moment(eachDealer.CreatedTime).tz('America/New_York');
                    if (eachDealer.Status === 0) {
                        eachDealer.userStatus = "Deactivated";
                    }
                    else if (eachDealer.userStatus = eachDealer.RoleType == 'CUSTOMER') {
                        eachDealer.userStatus = "Active";
                    }
                    else {
                        eachDealer.userStatus = eachDealer.RoleType !== 'VENDSREP' ? eachDealer.RoleApproval : "Active";
                    }
                });
                freezedUserList = __spreadArray([], dealerList_1, true);
                Object.freeze(freezedUserList);
                setDealerListToDisplay(dealerList_1);
            }
        }).catch(function (err) { setIsFetching(false); });
    };
    useEffect(function () {
    }, [vendorList]);
    useEffect(function () {
        if (selectedPortal) {
            getDealerListBasedOnVendor();
        }
    }, [selectedPortal]);
    useEffect(function () {
        var filteredList = __spreadArray([], freezedUserList, true);
        if (searchText) {
            filteredList = freezedUserList.filter(function (eachRec) {
                var fullStringToMatch = '';
                if (eachRec.FirstName) {
                    fullStringToMatch += eachRec.FirstName;
                }
                if (eachRec.RoleType) {
                    fullStringToMatch += " ".concat(eachRec.RoleType);
                }
                if (eachRec.Email) {
                    fullStringToMatch += " ".concat(eachRec.Email);
                }
                return fullStringToMatch.toLocaleLowerCase().includes(searchText.toLocaleLowerCase());
            });
        }
        if ((selectedStatus !== undefined && selectedStatus !== null) && selectedStatus !== PORTAL_STATUS[0].code) {
            var filteredCopyList = __spreadArray([], filteredList, true);
            var newList = filteredCopyList.filter(function (user) {
                return user.userStatus === selectedStatus;
            });
            filteredList = newList;
        }
        setDealerListToDisplay(filteredList);
    }, [searchText, selectedStatus]);
    useEffect(function () {
        return function () {
            setDealerListToDisplay([]);
            freezedUserList = [];
        };
    }, []);
    var tableActionHandler = function (rowData, actionType) {
        if (actionType === "Approved" || actionType === "Denied") {
            var payLoad = {
                Id: Number(rowData.Id),
                VendorId: Number(selectedPortal),
                RoleType: rowData.RoleType,
                RoleApproval: actionType === "Approved" ? 1 : 2
            };
            api_service.post({
                endPoint: api_urls.UPDATE_USER,
                payLoad: payLoad,
                showLoader: true
            }).then(function (res) {
                setOpenSuccess(true);
                if (res && res.data.statusCode === 200) {
                    var msg = actionType === "Approved" ? "User approved successfully !" : "User denied successfully !";
                    setMsgText(msg);
                    getDealerListBasedOnVendor();
                }
            }).catch(function (err) {
                setOpenSuccess(true);
            });
        }
        else if (actionType === "Deactivated") {
            var payload = {
                Id: Number(rowData.Id),
                VendorId: Number(selectedPortal),
            };
            api_service.delete({
                endPoint: api_urls.DEACTIVATE_USER,
                payLoad: payload
            }).then(function (res) {
                setOpenSuccess(true);
                if (res && res.data.statusCode === 200) {
                    setMsgText('User deactivated successfully !');
                    getDealerListBasedOnVendor();
                }
            }).catch(function (err) {
                setOpenSuccess(true);
            });
        }
    };
    var handleRowClickHandler = function (__rowData) {
        setRowData(__rowData);
        setOpen(true);
    };
    return (React.createElement(Box, { className: "common-module-wrapper user-management-wrapper" },
        React.createElement(ModuleHeader, { moduleName: 'User Management', placeHolderText: 'Search by Name/Role/Email', hasPortalDD: true, hasStatusDD: true, hasSearch: true, hasAddItem: false, getSelectedPortal: setSelectedPortal, getSearchedText: setSearchText, selectedStatus: setSelectedStatus, isDrawerOpen: function () { }, getVendorList: setVendorList, resetDealerFilters: freezedUserList === null || freezedUserList === void 0 ? void 0 : freezedUserList.length, feildWidth: 3.5 }),
        React.createElement(Box, { className: "module-common-table-wrapper" },
            React.createElement(ModuleTable, { dataToDisplay: dealerList, columns: UserMangementCols, isLoading: isFetching, isRowClickEnabled: true, onClickHandler: handleRowClickHandler, actionHandler: tableActionHandler, moduleName: "User Management", isPortalSelected: selectedPortal })),
        React.createElement(Box, null,
            React.createElement(UserModuleDrawer, { open: open, handleClose: function () { setRowData(null); setOpen(false); }, rowData: rowData, getDealerListBasedOnVendor: getDealerListBasedOnVendor })),
        React.createElement(ConfirmationAndInformationPoup, { popupType: POPUP_TYPE.SUCCESS_ALERT, titleText: 'Success!', open: openSuccess, message: msgText, acceptBtnText: 'Ok', acceptCallBack: function () {
                setOpenSuccess(false);
                setMsgText('');
            } })));
};
export default UserManagement;
